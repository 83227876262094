























































































































import { Vue, Component } from "vue-property-decorator";
import Despacho from "../../../entidades/Recepcion/Despacho";
import { State } from "vuex-class";
import { PlantaModel } from "@/store/Maestros/Plantas/types";
import { mensajes } from "@/plugins/notify";
import { HaciendaModel } from "@/store/Maestros/Hacienda/types";
const namespaceParte = "parteModule";
@Component({
  components: {},
})
export default class extends Vue {
  @State('haciendas', {namespace:namespaceParte} ) haciendas!: HaciendaModel[];
  @State("plantas", { namespace: "plantaModule" }) plantas!: PlantaModel[];
  local: Despacho = new Despacho();
  anioCultivo: any = "";
  cambioHacienda(val: number) {
    var tmp = this.haciendas.find((e) => e.haciendaId == val);
    if (tmp) 
    {
      this.local.hacienda = tmp;
      this.anioCultivo = tmp.descripcion;
      console.log("Asignado");
    } else {
      console.log("Falló");
    }
  }
  cambioPlanta(val: number) {
    console.log("Cambió: ", val, this.local);
    var tmp = this.plantas.find((e) => e.plantaId == val);
    if (tmp) {
      this.local.planta = tmp;
      console.log("Asignado");
    } else {
      console.log("Falló");
    }
  }
  
  save() {
    var resp=Despacho.isValid(this.local);
    if(resp.value){
      console.log("Llamado a guardar con ", this.local);
      this.$emit("guardar", this.local);
    }else{
      mensajes.error('ERROR: '+ resp.message);
    }
  }
}
