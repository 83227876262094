



























































import {Vue, Component} from 'vue-property-decorator'
import Despacho from '@/entidades/Recepcion/Despacho'
import {Lote} from '@/store/Maestros/Hacienda/types'
import Confirmacion from '@/components/Confirmacion.vue'
import DespachoDistribucion from '@/components/Forestal/Recepcion/DespachoDistribucion.vue'
import DespachoView from '@/components/Forestal/Recepcion/DespachoView.vue'
import {State, Action, Mutation} from 'vuex-class'
import {LoteDistribucionModel} from '@/entidades/Recepcion/LoteDistribucion';
import {despachoApi} from '@/entidades/Recepcion/Despacho'
import TablaReutilizable from '@/components/TablaReutilizable.vue'
import { TableBtnModel } from '@/entidades/TableBtnModel';
import { mensajes } from '@/plugins/notify'
@Component({
    components:{
        DespachoView,
        Confirmacion,
        DespachoDistribucion,
        TablaReutilizable
    }
})

export default class extends Vue
{
    eliminando: boolean= false;
    enviando: boolean= false;
    editando: boolean= false;
    nombreComponente='DespachoView'
    DespachoDistribucionName='DespachoDistribucion'
    local:Despacho=new Despacho();
    editedIndex: number= -1;
      editedItem: Despacho=new Despacho();
      defaultItem: Despacho=new Despacho();
    headers:any[]= [
        {
          text: 'Num. Despacho',
          align: 'start',
          sortable: false,
          value: 'numeroDespacho'
        },
        {
          text: 'Origen',
          align: 'start',
          sortable: false,
          value:'planta.descripcion'
        },
        {
          text: 'Hacienda',
          align: 'start',
          sortable: false,
          value: 'hacienda.descripcion'
        },
        {
          text: 'Cant. Plantas',
          align: 'end',
          sortable: false,
          value: 'cantidadPlantas'
        },
        {
          text: 'Cant. Almacigueras',
          align: 'end',
          sortable: false,
          value: 'cantidadAlmacigueras'
        },
        { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },        
    ];
    botones:TableBtnModel[]=[
	{
		icon:'fa-clipboard-list',
		label:'',
		action:'AbrirDistribuir',
		tooltip:'Distribución de plantulas y almacigueras',
		necesitaIndex:false
	},
	{
		icon:'fa-share',
		label:'',
		action:'AbrirEnviar',
		tooltip:'Registrar',
		necesitaIndex:false
	},
	{
		icon:'fa-trash',
		label:'',
		action:'AbrirEliminar',
		tooltip:'Eliminar',
		necesitaIndex:false
	},
];
    agregando:Boolean=false;
    @State('despachos', {namespace:'despachoModule'} ) despachos!: Despacho[];
    @Mutation('addDespacho', {namespace:'despachoModule'} ) agregarDespacho!:Function;
    @Mutation('deleteDespacho', {namespace:'despachoModule'} ) deleteDespacho!:Function;
    @Mutation('editDespacho', {namespace:'despachoModule'} ) editDespacho!:Function;
    @Action('enviar', {namespace:'despachoModule'} ) enviar!: Function;
    cerrarModal()
    {
        this.agregando=false;
    }
    agregar(item:Despacho)
    {
        item.hacienda?.lotes.forEach((el:Lote)=>{
            var tmp=new LoteDistribucionModel();
            tmp.loteId=el.loteId;
            tmp.descripcion=el.descripcion;
            tmp.hectareas=el.hectareas;
            tmp.estado=true;
            tmp.haciendaId=item.hacienda.haciendaId;
            tmp.cantidadPlantas=0;
            item.distribucion.push(tmp)
        })
        this.agregarDespacho(item);
        this.agregando=false;
    }
    
    AbrirEliminar (item:Despacho) {
        console.log('Se eliminará: ', item)
        this.editedIndex = this.despachos.indexOf(item);
        if(this.editedIndex>=0){
            this.editedItem = Object.assign({}, item)
            this.eliminando = true
        }else{
            mensajes.error('Error al obtener el índice del elemento');
        }
    }
    AbrirDistribuir(item:Despacho){
        console.log("Se distribuirá ", item)
        var _ = require('lodash');
        this.local=_.cloneDeep(item);
        this.editando=true;
    }
    async AbrirEnviar(item:Despacho){
        var totalPlantulas=0;
        var totalAlmacigueras=0;
        
        item.distribucion.forEach(element => {
            //@ts-ignore
            totalPlantulas+=parseInt(element.cantidadPlantas);
            //@ts-ignore
            totalAlmacigueras+=parseInt(element.cantidadAlmacigueras);
        });
        if(totalPlantulas==item.cantidadPlantas && totalAlmacigueras==item.cantidadAlmacigueras){
            var tmp:despachoApi = Despacho.preparar(item);
            tmp.usuarioId=this.$store.state.authModule.usuarioId;
            
            console.log('Transformado: ', tmp, "como string: ", JSON.stringify(tmp));
            mensajes.advertencia('Enviando datos, por favor espere...');
            await this.enviar(tmp);
        }else{
            mensajes.error('No ha realizado la distribucion');
            console.log("No ha realizado la distribucion", totalPlantulas, item.cantidadPlantas)
        }
        
    }
    
    
    deleteItemConfirm () {
    this.deleteDespacho(this.editedIndex)
    this.eliminando=false;
    }
    editarConfirmar(item: Despacho)
    {
        this.editDespacho(item);
        this.editando=false;
    }

    deleteCancel(){
        this.eliminando=false;
    }
    editCancel(){
        this.editando=false;
    }
}
