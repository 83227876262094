export default class BaseHacienda {
    areaTotal?: number;
    areaPlantada?: number;
    areaDisponible?: number;
    zonaProteccionHidrica?: number;
    zonaReservaForestal?: number;
    areaLineaElectrica?: number;
    areaOleoducto?: number;
    areaCampamento?: number;
    areaCaminos?: number;
    areaSubsolada?: number;
    constructor(_areaTotal?:number,_areaPlantada?:number,_areaDisponible?:number,_areaProteccionHidrica?:number,
        _areaReservaForestal?:number,_areaLineaElectrica?:number,_areaOleoducto?:number,_areaCampanento?:number,_areaCaminos?:number,_areaSubsolada?:number
        )
        {
            this.areaTotal = _areaTotal;
            this.areaPlantada = _areaPlantada;
            this.areaDisponible = _areaDisponible;
            this.zonaProteccionHidrica = _areaProteccionHidrica;
            this.zonaReservaForestal = _areaReservaForestal;
            this.areaLineaElectrica = _areaLineaElectrica;
            this.areaOleoducto = _areaOleoducto;
            this.areaCampamento = _areaCampanento;
            this.areaCaminos = _areaCaminos;
            this.areaSubsolada = _areaSubsolada;
        }
}