import {HaciendaLote} from "../Plantacion/HaciendaLote";

export interface LoteDistribucion extends HaciendaLote{
    cantidadPlantas:number;
    cantidadAlmacigueras:number;
}

export class LoteDistribucionModel extends HaciendaLote{
    loteId?=0;
    descripcion?='';
    hectareas?=0;
    estado?=true;
    haciendaId?=0;
    cantidadPlantas?=0;
    cantidadAlmacigueras?=0
}