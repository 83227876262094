import {HaciendaModel} from '@/store/Maestros/Hacienda/types';
import {Planta} from '../Maestro/Planta'
import {LoteDistribucionModel} from '@/entidades/Recepcion/LoteDistribucion'
import {DespachoModel} from '@/store/Maestros/Despachos/types'
import Hacienda from '../Plantacion/Hacienda';

export default class Despacho implements DespachoModel
{
    
    constructor(){
        this.distribucion=new Array<LoteDistribucionModel>();
        this.numeroDespacho='';
        this.hacienda=new HaciendaModel();
        this.planta=new Planta();
        this.cantidadPlantas=0;
        this.cantidadAlmacigueras=0;
        this.almaciguerasEnviadas=0;
        this.plantulasEnviadas=0;
        this.guiaRemision=''
        this.placa=''
    }
    numeroDespacho: string;
    hacienda: HaciendaModel;
    planta: Planta;
    cantidadPlantas: Number;
    plantulasEnviadas: Number;
    cantidadAlmacigueras: Number;
    almaciguerasEnviadas: Number;
    guiaRemision: string;
    placa: string;
    distribucion: LoteDistribucionModel[];
    static isValid(despacho: Despacho){
        var salida={
            value:true,
            message:''
        }
        if(despacho.planta){
            if(despacho.planta.plantaId){
                if(despacho.planta.plantaId==0){
                    salida.message='Debe especificar una planta';
                    salida.value=false;
                    return salida;
                }
            }else{
                salida.message='Debe especificar una planta';
                salida.value=false;
                return salida;
            }
        }else{
            salida.message='Debe especificar una planta';
            salida.value=false;
            return salida;
        }

        if(despacho.hacienda){
            if(despacho.hacienda.haciendaId==0){
                salida.message='Debe especificar una hacienda';
                salida.value=false;
                return salida;
            }
        }else{
            salida.message='Debe especificar una hacienda';
            salida.value=false;
            return salida;
        }
        if(despacho.numeroDespacho.length==0){
            salida.message='Número de despacho inválido';
            salida.value=false;
            return salida;
        }

        if(Number(despacho.plantulasEnviadas)<=0){
            salida.message='Cantidad de plantulas enviadas inválida'
            salida.value=false;
            return salida;
        }
        if(Number(despacho.cantidadPlantas)<=0){
            salida.message='Cantidad de plantulas recibidas inválida'
            salida.value=false;
            return salida;
        }else{
            if(Number(despacho.cantidadPlantas)>Number(despacho.plantulasEnviadas)){
                salida.message='Cantidad de plantulas recibidas inválida'
                salida.value=false;
                return salida;
            }
        }
        if(Number(despacho.almaciguerasEnviadas)<=0){
            salida.message='Cantidad de Almacigueras enviadas inválida'
            salida.value=false;
            return salida;
        }
        if(Number(despacho.cantidadAlmacigueras)<=0){
            salida.message='Cantidad de Almacigueras recibidas inválida'
            salida.value=false;
            return salida;
        }else{
            if(Number(despacho.cantidadAlmacigueras)>Number(despacho.almaciguerasEnviadas)){
                salida.message='Cantidad de Almacigueras recibidas inválida'
                salida.value=false;
                return salida;
            }
        }

        if(despacho.guiaRemision.length<=6){
            salida.message='Guía de remisión inválida';
            salida.value=false;
            return salida;
        }
        if(despacho.placa.length<=5){
            salida.message='Placa inválida';
            salida.value=false;
            return salida;
        }

        return salida;
    }
    static preparar(despacho: Despacho):despachoApi{
        var salida=new despachoApi()
        //@ts-ignore
        salida.cantidadPlantulaEnviada=parseInt(despacho.plantulasEnviadas);
        //@ts-ignore
        salida.cantidadPlantuaRecibida=parseInt(despacho.cantidadPlantas);
        //@ts-ignore
        salida.cantidadAlmacigueraEnviada=parseInt(despacho.almaciguerasEnviadas);
        //@ts-ignore
        salida.cantidadAlmacigueraRecibida=parseInt(despacho.cantidadAlmacigueras);
        //@ts-ignore
        salida.guiaRemision=despacho.guiaRemision;
        //@ts-ignore
        salida.placa=despacho.placa;
        salida.numeroDespacho=despacho.numeroDespacho;
        
        salida.fecha=new Date().toISOString().substr(0, 10);
        despacho.distribucion.forEach(el=>{
            var tmp=new distribucionApiModel();
            //@ts-ignore
            tmp.loteId=el.loteId;
            //@ts-ignore
            tmp.haciendaId=el.haciendaId;
            //@ts-ignore
            var plantulas=parseInt(el.cantidadPlantas);
            tmp.cantidadPlantula=plantulas;
            //@ts-ignore
            var almacigueras=parseInt(el.cantidadAlmacigueras);
            tmp.cantidadAlmaciguera=almacigueras;
            if(plantulas>0||almacigueras>0){
                salida.plantulaDetalleModels.push(tmp);
            }
        })
        return salida;
    }
}
export class despachoApi
{
    cantidadPlantulaEnviada:number;
    cantidadPlantuaRecibida:number;
    cantidadAlmacigueraEnviada:number;
    cantidadAlmacigueraRecibida:number;
    fecha:string;
    usuarioId:string;
    guiaRemision:string;
    numeroDespacho:string;
    placa:string;
    plantulaDetalleModels:distribucionApi[];
    constructor(){
        this.cantidadPlantulaEnviada=0;
        this.cantidadPlantuaRecibida=0;
        this.cantidadAlmacigueraEnviada=0;
        this.cantidadAlmacigueraRecibida=0;
        this.usuarioId='';
        this.guiaRemision='';
        this.placa='';
        this.plantulaDetalleModels=new Array<distribucionApi>();
        this.fecha='';
        this.numeroDespacho=''
    }
}
export interface distribucionApi{
    loteId:number;
    haciendaId:number;
    cantidadAlmaciguera:number;
    cantidadPlantula:number;
}
export class distribucionApiModel implements distribucionApi{
    loteId: number;
    haciendaId: number;
    cantidadAlmaciguera: number;
    cantidadPlantula: number;
    constructor(){
        this.loteId=0
        this.haciendaId=0;
        this.cantidadAlmaciguera=0;
        this.cantidadPlantula=0;
    }
}