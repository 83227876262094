
















































import Despacho from '@/entidades/Recepcion/Despacho'
import { mensajes } from '@/plugins/notify';
import {  Vue ,Component, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue
{
    @Prop({type:Despacho ,required:true})
    despacho!:Despacho;
    guardar(){
        var totalPlantulas=0;
        var totalAlmacigueras=0;
        
        this.despacho.distribucion.forEach(element => {
            //@ts-ignore
            totalPlantulas+=parseInt(element.cantidadPlantas);
            //@ts-ignore
            totalAlmacigueras+=parseInt(element.cantidadAlmacigueras);
        });
        if(totalPlantulas==this.despacho.cantidadPlantas && totalAlmacigueras==this.despacho.cantidadAlmacigueras){
            this.$emit('onOk', this.despacho);
        }else{
            mensajes.error("Cantidad incorrecta, debe distribuir todo el stock de plantas y almacigueras");
        }
    }

    obtenerRestante():number{
        var total=0
        this.despacho.distribucion.forEach(element => {
            if(element.cantidadPlantas){
                //@ts-ignore
                total+=parseInt(element.cantidadPlantas);
            }
        });
        //@ts-ignore
        return this.despacho.cantidadPlantas-total
    }
    obtenerRestanteAlmacigueras():number{
        var total=0
        this.despacho.distribucion.forEach(element => {
            if(element.cantidadAlmacigueras){
                //@ts-ignore
                total+=parseInt(element.cantidadAlmacigueras);
            }
        });
        //@ts-ignore
        return this.despacho.cantidadAlmacigueras-total
    }
    
}
