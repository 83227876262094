import Hacienda from "./Hacienda";
import HaciendaBase from "./HaciendaBase";

export class HaciendaLote extends HaciendaBase
{
    haciendaLoteId?:number =0;
    descripcion?:string = "";
    estado?:boolean  =true;
    haciendaId?:number  =0;
    hacienda:Hacienda | undefined;
    latitud:number =0;
    longitud:number =0;
    ordernInterna:string="";

    constructor(_haciendaId?:number,_haciendaLoteId?:number,_descripcion?:string,_estado?:boolean,
        _hacienda?:Hacienda | undefined,_latitud?:number,_longitud?:number,_areaTotal?:number,_areaPlantada?:number,_areaDisponible?:number,_areaProteccionHidrica?:number,
        _areaReservaForestal?:number,_areaLineaElectrica?:number,_areaOleoducto?:number,_areaCampanento?:number,_areaCaminos?:number,_areaSubsolada?:number
        )
    {
        super(_areaTotal,_areaPlantada,_areaDisponible,_areaProteccionHidrica,
            _areaReservaForestal,_areaLineaElectrica,_areaOleoducto,_areaCampanento,_areaCaminos,_areaSubsolada);
        this.haciendaId = _haciendaId;
        this.descripcion = _descripcion;
        this.estado = true;
    }
}

export class HaciendaLoteCustomer
{

    loteId?:number =0;
    descripcion?:string = "";
    latitud:number =0;
    longitud:number =0;

}
